<template>
  <div :class="generalClass">
    <div
      class="row"
      :class="$store.getters['layout/getInnerWidth'] < 900 ? 'p-2' : ''"
    >
      <div :class="getTitleClass">
        <h6 class="mt-4 title-perso-infs">
          {{ $t('views.client.details.infos.sub-details.title') }}
        </h6>
      </div>
      <div :class="getBorderClass" class="border-bottom-title">
      </div>
      <div class="container-fluid">
        <b-row
          v-if="client.accessCode"
          :class="'mt-2 '+getMargins"
        >
          <b-col class="left-general-infs">
            {{ $t('views.client.details.infos.sub-details.access-code') }}
          </b-col>
          <b-col
            class="right-general-infs text-right pointer qr-code-hover"
            @click="displayQrCode = !displayQrCode"
          >
              <span>
              <i
                class="pointer icofont icofont-qr-code"
              ></i>
            </span>
            {{ client.accessCode }}
          </b-col>
        </b-row>
        <b-row v-if="client.company && client.company.trim().length" :class="'mt-2 '+getMargins">
          <b-col class="left-general-infs">
            {{ $t('views.client.details.infos.sub-details.company') }}
          </b-col>
          <b-col
            class="right-general-infs text-right"
          >
            {{ client.company }}
          </b-col>
        </b-row>
        <b-row :class="'mt-2 '+getMargins">
          <b-col class="left-general-infs">
            {{ $t('views.client.details.infos.sub-details.medical-certification') }}
          </b-col>
          <b-col class="right-general-infs text-right">
            {{ $t('general.actions.' + client.medicalCertificate) }}
          </b-col>
        </b-row>
        <b-row
          v-if="client.licenceNumber"
          :class="'mt-2 '+getMargins"
        >
          <b-col class="left-general-infs">
            {{ $t('views.client.details.infos.sub-details.licence-number') }}
          </b-col>
          <b-col class="right-general-infs text-right">
            {{ client.licenceNumber }}
          </b-col>
        </b-row>
        <b-row
          :class="'mt-2 '+ getMargins"
        >
          <b-col class="left-general-infs">
            {{ $t('views.client.details.infos.sub-details.affiliated') }}
          </b-col>
          <b-col class="right-general-infs text-right">
            {{ $t('general.actions.' + client.affiliated) }}
          </b-col>
        </b-row>
        <b-row
          v-if="client.description && client.description.trim().length"
          :class="'mt-2 '+getMargins"
        >
          <b-col class="left-general-infs">
            {{ $t('views.client.details.infos.sub-details.description') }}
          </b-col>
          <b-col class="right-general-infs text-right">
            {{ client.description }}
          </b-col>
        </b-row>
      </div>
    </div>
    <qr-access-code
      modal-id="qrCodeModalId"
      :customer="client"
      :display="displayQrCode"
    />
  </div>
</template>
<script>
import QrAccessCode from "@custom/clients/infos/QrAccessCode";

export default {
  components: {QrAccessCode},
  props: {
    client: {
      type: Object,
      default: () => {
      },
    },
    generalClass: {
      type: String,
      default: '',
    }
  },
  data: () => ({
    innerWidth: 1600,
    displayQrCode: false,
  }),
  computed: {
    getMargins() {
      if (this.innerWidth < 1200) {
        return '';
      } else {
        return 'ml-3 mr-2';
      }
    },
    getCols() {
      if (this.innerWidth < 900) {
        return '';
      } else {
        return '8';
      }
    },
    getTitleClass() {
      if (this.innerWidth > 1592 && this.innerWidth < 1850) {
        return 'col-md-4'
      } else if (this.innerWidth > 1485 && this.innerWidth < 1592) {
        return 'col-md-4'
      } else if (this.innerWidth > 1200 && this.innerWidth < 1485) {
        return 'col-md-4'
      } else if (this.innerWidth > 700 && this.innerWidth < 1200) {
        return 'col-md-12'
      } else {
        return 'col-md-3'
      }


    },
    getBorderClass() {
      if (this.innerWidth > 1592 && this.innerWidth < 1850) {
        return 'col-md-8'
      } else if (this.innerWidth > 1485 && this.innerWidth < 1592) {
        return 'col-md-8'
      } else if (this.innerWidth > 1200 && this.innerWidth < 1485) {
        return 'col-md-8'
      } else if (this.innerWidth > 700 && this.innerWidth < 1200) {
        return 'col-md-12'
      } else {
        return 'col-md-9'
      }

    }
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
  },
  created() {
    this.innerWidth = window.innerWidth;
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  }
}
</script>
<style scoped>
.title-perso-infs {
  font: normal normal bold 21px/13px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}

@media only screen and (max-width: 1527px) {
  .title-perso-infs {
    font: normal normal bold 18px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

@media only screen and (max-width: 791px) {
  .title-perso-infs {
    font: normal normal bold 10px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

@media only screen and (max-width: 991px) {
  .title-perso-infs {
    font: normal normal bold 14px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

.border-bottom-title {
  border-bottom: 2px solid #EDF0F3;
}

.right-general-infs {
  text-align: left;
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 0.5;
}

.qr-code-hover:hover {
  color: #55565b;
  opacity: 1;
}

.left-general-infs {
  text-align: left;
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}

</style>
